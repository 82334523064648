import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { DtoListHttp } from '@app/models/dto-list-http.model';
import { AlertModel } from '@app/models/notifications/alert.model';
import { ResponseNotifications } from '@app/models/notifications/response-notifications.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private readonly API_URI = environment.PROXY
        ? '/api/'
        : environment.API_URL_V1;
    private http = inject(HttpClient);

    notifications(dto: DtoListHttp): Observable<ResponseNotifications> {
        let params = new HttpParams();
        params = params.append('skip', dto.skip);
        params = params.append('limit', dto.limit);

        return this.http.get<ResponseNotifications>(
            `${this.API_URI}notifications`,
            {
                params,
            }
        );
    }

    updateNotification(notificationId: string): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}notifications`, {
            notificationId,
        });
    }

    updateNotificationAll(): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}notifications/all`, {});
    }

    public create(data: FormData): Observable<void> {
        return this.http.post<void>(`${this.API_URI}alert`, data);
    }

    public edit(data: FormData): Observable<void> {
        return this.http.patch<void>(`${this.API_URI}alert`, data);
    }

    public get(id: string): Observable<any> {
        return this.http.get<any>(`${this.API_URI}alert?alertId=${id}`);
    }

    public delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.API_URI}alert?alertId=${id}`);
    }

    public getAll(): Observable<any> {
        return this.http.get<any>(`${this.API_URI}alert/all`);
    }
}
